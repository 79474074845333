body {
  margin: 0;
  font-family: Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "DINNext";
  src: local("DINNextW1G-Bold"),
  url("./fonts/DINNextW1G-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face{ 
  font-family:"DINNext";
  src: local("DINNextW1G-Regular"),
  url("./fonts/DINNextW1G-Regular.otf") format("opentype");
}

@font-face {
  font-family: "GlyphaLTPro";
  src: local("GlyphaLTPro-Bold"),
  url("./fonts/GlyphaLTPro-Bold.otf") format("opentype");
  font-weight: bold;
}