.buttonh:hover{
    background:'#00629B'
}

#logo {
    width: 100%;
    height: 100%;
}

#logo_container {
    width: 120px;
    padding-left: 0.5rem;
}

#banner {
    width: 100%;
    height: 100%;
}

#banner_container {
    width: 90vw;
    height: 6vw;
}

.gfColor {
    color:'#00629B'
}

.footer{
    /* margin-top: 1rem; */
    /* padding: 1rem; */
    /* background-color: #003E51; */
    background-color: #1965A3;
    padding-left: 1rem;
    padding-right: 1rem;
    /* margin-Bottom:0;  */
    left: 0;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.footer p {
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.colorblue{color:#07495e;}
.comment0{display:none;}
.comment1{display:block;}

.commentContainer {
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 1px solid #CCCCCC;
}

.commentUserName {
    font-weight: bold;
    padding-right: 0.5rem;
}

.commentTimeStamp {
    font-weight: normal;
    font-size: 0.8rem;
    opacity: 0.8;
}

.commentIconAndText:hover {
    cursor: pointer;
    text-decoration: underline;
}

.commentsList {
    max-height: 350px;
    margin-top: 0.5rem;
    overflow-y: scroll;
}

.pendindAccordionBodyContainer {
    max-height: 50vh;
    overflow-y: auto;
}

.toggleContainer {
    margin-right: 0.5rem;
}

.toggleContainer>.btn {
    border-width: 2px;
    padding: 2px 6px;
}

.feedbackButton > button {
    background-color: #FF6600;
    border-color: #FF6600;
}

.feedbackButton > button:hover {
    opacity: 0.7;
    background-color: #FF6600;
    border-color: #FF6600;
}

.accordionHeaderContainer {
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.requireAttention {
    font-size: 1rem;
}

.accordionHeaderFirst {
    flex: 1;
}

.accordionHeaderThird {
    font-size: 1rem;
    text-align: center;
    flex: 1;
}

.app_choice_name {
    margin-bottom: 0.5rem;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    color: #003E51;
    padding-top: 0;
    padding-bottom: 7px;
    font-size: 1.5rem;
    /* border: 1px solid #003E51; */
    margin-top: 0;
    /* box-shadow: 2px solid #ccc; */
    background-color: #fff;
}

.app_img_name_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #003E51;
    width: 80%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 3px #ccc;
}

.app_img_name_container:hover {
    opacity: 0.7;
}

.app_img_name_container img {
    width: 90%;
}

.app_nav_container {
    padding-bottom: 2rem;
}

.loginPage {
    /* display: flex;
    flex-direction: row; */
    display: grid;
    gap: 0px;
    grid-template-columns: 43% 57%;
	grid-template-areas: "graphic  login";
    height: 100vh;
    max-height: 30rem;
    width: 100%;
    max-width: 50rem;
    /* background-color: #003E51; */
    /* background-color: rgb(241, 244, 249); */
    background-color: rgb(240, 240, 240);
    position: absolute;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    border-radius: 6px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 4px 2px, rgba(60, 64, 67, 0.15) 0px 1px 5px 2px;
}

.graphic-container {
    grid-area: graphic;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* background-image: url('./images/Sparkle_Cloud_Evolution_RGB.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-size: 100% 50%; */
}

.graphic-container img{
    max-width: 80%;
    /* margin: auto; */
}

.login-container {
    grid-area: login;
    border: none;
    border-left: 1px solid #d1d1d1;
    /* background-color: rgb(241, 244, 249) !important; */
    background-color: #fff;
    position: relative;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 2rem;
    padding-top: 1rem;
    position: relative;
    /* background-image: url('./images/Sparkle_Cloud_Progress_RGB.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size: 65% 65%; */
}

/* .login-container input {
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(60, 64, 67, 0.3) 1px 1px 2px 0px;
    border-radius: 3px;
} */

.login-container-elements {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.login-page-background {
    width: 100%;
    height: 100%;
    /* background-image: url('./images/Map_Wall_BLU.jpg'); */
    background-image: url('./images/Map_Wall_copy copy2.jpg');
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    background-size: cover;
    position: relative;
    filter: blur(0px) brightness(0.6);
    -webkit-filter: blur(0px) brightness(0.6);
    opacity: 1;
}

.my-keys-title {
    margin: auto;
    text-align: center;
    font-family: GlyphaLTPro;
    /* color: white; */
    /* color: #003E51; */
    color:#1965a3;
    margin-bottom: 1.5rem;
    margin-top: 0;
}

.my-keys-signin-title {
    margin: auto;
    text-align: center;
    font-family: GlyphaLTPro;
    color: #1965a3;
    font-size: 2rem;
    padding-bottom: 1rem;
}

.login-icon-container {
    width: 8rem;
    height: 8rem;
    /* color: white; */
}

.login-icon-container svg{
    width: 100%;
    height: auto;
    stroke-width: 1;
    color: #1965a3;
}

.lock-icon svg{
    stroke-width: 0;
}

.login-input-container {
    width: 100%;
}

.login-input-label {

}

.login-input {
    width: 100%;
    height: 3rem;
    padding: 0 0.8rem;
    margin-bottom: 1rem;
    /* border-radius: 5px; */
    border: 1px solid #e0dede;
    box-shadow: none;
    background-color: #f0f0f0;
}

.login-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
}

.account-actions-container {
    display: flex;
    flex-direction: column;
    /* margin-top: 1.5rem; */
}

.account-actions-button {
    border: none;
    padding: none;
    text-align: right;
    background-color: transparent;
    color: #1965a3;
}

.account-actions-button:hover {
    text-decoration: underline;
    color: #66c2ff;
}

.login-button {
    border: none;
    font-weight: bold;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 3rem;
    color: #fff;
    background-color: #FF6600;
    font-size: 16px;
}

.login-back-button-container {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    font-size: 1.7rem;
    cursor: pointer;
    color: #33adff;
}

.invalid-new-password {
    outline: 1px solid red;
    border-color: red;
    color: red;
    background-color: rgba(255,0,0,0.1);
}

.valid-new-password {
    outline: 1px solid green;
    border-color: green;
    color: green;
    background-color: rgba(0,255,0,0.1);
}

/* .login-back-button-container svg {
    width: 
} */