.btn-block {
    width: 100%;
}

.custom-modal {
    width: fit-content;
    top: 10%;
}

.modal-backdrop {
    opacity: 0.8;
}

.modal-confirm {		
	color: #636363;
	width: 300px;
	/* font-size: 14px; */
}

.modal-confirm .modal-content {
	/* padding: 20px; */
	border-radius: 5px;
	border: none;
}

.modal-confirm .custom-modal-header {
	border-bottom: none;   
	position: relative;
}

.modal-confirm h4 {
	text-align: center;
	font-size: 26px;
	margin: 0.6rem 0 -0.4rem;
}

.modal-confirm .form-control, .modal-confirm .btn {
	min-height: 40px;
	border-radius: 3px; 
}

.modal-confirm .close {
	position: absolute;
	top: -5px;
	right: -5px;
}

.modal-confirm .modal-footer {
	border: none;
	text-align: center;
	border-radius: 5px;
	font-size: 13px;
}

.modal-confirm .icon-box-success {
	color: #fff;		
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: -70px;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	z-index: 9;
	background: #82ce34;
	padding: 15px;
	text-align: center;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box-success i {
	font-size: 58px;
	position: relative;
	bottom: 1.5rem;
    right: 4px
}

.modal-confirm .icon-box-fail {
	color: #fff;		
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: -70px;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	z-index: 9;
	background: #dc3545;
	padding: 15px;
	text-align: center;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box-fail i {
	font-size: 42px;
	position: relative;
	bottom: 0.8rem;
}

.modal-confirm .btn {
	color: #fff;
	border-radius: 4px;
	text-decoration: none;
	transition: all 0.4s;
	line-height: normal;
	border: none;
}

.modal-confirm .btn-success {
    background: #82ce34;
}

.modal-confirm .btn-success:hover, .modal-confirm .btn-success:focus {
	background: #6fb32b;
	outline: none;
}

/* .modal-confirm .btn-success:hover, .modal-confirm .btn-success:focus {
	background: #6fb32b;
	outline: none;
} */

.loadingMessage {
    font-size: 1.2rem;
}

.text-center {
    margin-bottom: 0;
}

.modal-dialog {
    margin-bottom: 0;
}

.modal-body {
    padding: 0.25rem;
}